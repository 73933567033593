<template>
  <v-card>
    <v-container class="pa-6 mx-0">
      <v-row
        class="align-center mb-0"
      >
        <v-col
          cols="12"
        >
          <h2>
            Jadwal Pelajaran
          </h2>
          <small style="white-space: normal;">
            Halaman untuk menambahkan jadwal pelajaran
          </small>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-autocomplete
              v-model="classUuid"
              label="Pilih Kelas"
              outlined
              :items="listClass"
              item-text="name"
              item-value="uuid"
              dense
              @change="getClassUuid($event)"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="semesters"
              label="Semester"
              outlined
              disabled
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="school_year"
              label="Tahun Ajaran"
              outlined
              disabled
              dense
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showSchedule == true">
        <v-col
          v-for="(hours, day) in lessonHours"
          :key="day"
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card
            class="ma-0 ma-sm-0 mr-md-1 mr-lg-1 mr-xl-1"
            height="100%"
          >
            <v-card-title><span class="text-h6 font-weight-medium">{{ day.toUpperCase() }}</span></v-card-title>
            <div
              v-if="hours.length < 1"
              class="pa-4"
            >
              <span class="d-block text-subtitle-2 my-2 text-center font-weight-light">
                Belum ada Jam Pelajaran
              </span>
              <a
                class="d-block text-subtitle-2 my-2 text-center"
                @click.prevent="$emit('next-tab')"
              >
                Buat Jam Pelajaran
              </a>
            </div>
            <v-simple-table v-else>
              <template>
                <thead>
                  <tr>
                    <th class="text-uppercase">
                      Jam
                    </th>
                    <th class="text-uppercase">
                      Mata Pelajaran
                    </th>
                    <th class="text-uppercase">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(hour, index) in hours"
                    :key="index"
                    class="align-center"
                  >
                    <td>{{ hour.start_at.slice(0,5) }} - {{ hour.end_at.slice(0,5) }}</td>
                    <td class="py-3">
                      <v-autocomplete
                        label="Mata Pelajaran"
                        :value="checkValue(day, hour)"
                        :items="listSubject"
                        :item-text="listSubject =>`${listSubject.name} - ${listSubject.teacher.employee.name}`"
                        item-value="uuid"
                        outlined
                        dense
                        class="ma-0 search"
                        @input="schedule[day].subject_uuid = $event"
                        @change="addHours(day,hour.uuid,getValue(day, hour))"
                      >
                      </v-autocomplete>
                    </td>
                    <td>
                      <div>
                        <v-img
                          width="20"
                          height="20"
                          src="../../assets/icons/trash.svg"
                          class="cursor-pointer p-0"
                          @click="deleteSchedule(getValue(day, hour))"
                        >
                        </v-img>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'LessonSchedule',
  data() {
    return {
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      isLoadingCard: true,
      icons: {
        mdiMagnify,
      },
      schedule: {
        senin: {
          uuid: '',
          class_uuid: '',
          schedule_uuid: '',
          subject_uuid: '',
          day: 'senin',
          semester: '',
          school_year: '',
        },
        selasa: {
          uuid: '',
          class_uuid: '',
          schedule_uuid: '',
          subject_uuid: '',
          day: 'selasa',
          semester: '',
          school_year: '',
        },
        rabu: {
          uuid: '',
          class_uuid: '',
          schedule_uuid: '',
          subject_uuid: '',
          day: 'rabu',
          semester: '',
          school_year: '',
        },
        kamis: {
          uuid: '',
          class_uuid: '',
          schedule_uuid: '',
          subject_uuid: '',
          day: 'kamis',
          semester: '',
          school_year: '',
        },
        jumat: {
          uuid: '',
          class_uuid: '',
          schedule_uuid: '',
          subject_uuid: '',
          day: 'jumat',
          semester: '',
          school_year: '',
        },
        sabtu: {
          uuid: '',
          class_uuid: '',
          schedule_uuid: '',
          subject_uuid: '',
          day: 'sabtu',
          semester: '',
          school_year: '',
        },
      },
      showAdd: {},
      showSchedule: false,
      tes: '',
      semesters: '',
      school_year: '',
      school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
      classSchedule: '',
      classUuid: '',
      listClass: [],
      listSubject: [],
      lessonHours: [],
      listLessonHours: [],
      lessonHour: {
        senin: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'senin',
          start_at: '',
          end_at: '',
        },
        selasa: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'selasa',
          start_at: '',
          end_at: '',
        },
        rabu: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'rabu',
          start_at: '',
          end_at: '',
        },
        kamis: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'kamis',
          start_at: '',
          end_at: '',
        },
        jumat: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'jumat',
          start_at: '',
          end_at: '',
        },
        sabtu: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'sabtu',
          start_at: '',
          end_at: '',
        },
      },
    }
  },
  watch: {
    getClassUuid: {
      handler() {
        this.getClassUuid()
        this.getSchedule()
      },
    },
  },
  mounted() {
    this.getListClass()
    this.checkedRadio()
    this.getListSubject()
    this.getHours()
  },
  methods: {
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    checkValue(day, hour) {
      let subjectUuid = ''
      if (this.listLessonHours[day] !== undefined) {
        this.listLessonHours[day].forEach(element => {
          if (element.schedule.start_at === hour.start_at) subjectUuid = element.subject.uuid
        })

        return subjectUuid
      }

      return true
    },
    getValue(day, hour) {
      let subjectUuid = ''
      if (this.listLessonHours[day] !== undefined) {
        this.listLessonHours[day].forEach(element => {
          if (element.schedule.start_at === hour.start_at) subjectUuid = element.uuid
        })

        return subjectUuid
      }

      return true
    },
    async getListClass() {
      await this.$services.ApiServices.list('class', { school_uuid: this.school_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.listClass = data.data
        },
        err => console.error(err),
      )
    },

    async checkedRadio() {
      await this.$services.ApiServices.get('school', this.school_uuid).then(({ data }) => {
        this.semesters = data.data.semester
        this.school_year = data.data.tahun_ajaran
      })
    },

    async getListSubject(params = {}) {
      await this.$services.ApiServices.list('subject', {
        ...params,
        school_uuid: this.school_uuid,
        per_page: 'all',
        class_uuid: this.classUuid,
      }).then(
        ({ data }) => {
          this.listSubject = data.data.filter(item => item.is_active === 'Active')
        },
        err => console.error(err),
      )
    },

    getClassUuid(uuid) {
      this.classUuid = uuid
      this.showSchedule = true
      this.getSchedule()
      this.getListSubject()
    },

    async getHours() {
      await this.$services.ApiServices.get('hours', this.school_uuid).then(({ data }) => {
        this.lessonHours = data.data
      })
    },

    async getSchedule(params = {}) {
      await this.$services.ApiServices.listParams('schedule', this.classUuid, {
        ...params,
        semester: this.semesters,
        school_year: this.school_year,
      }).then(({ data }) => {
        this.listLessonHours = data.data
      })
    },

    async addHours(day, uuid, uuidHour) {
      this.schedule[day].semester = this.semesters
      this.schedule[day].school_year = this.school_year
      this.schedule[day].class_uuid = this.classUuid
      this.schedule[day].schedule_uuid = uuid
      this.schedule[day].uuid = uuidHour
      await this.$services.ApiServices.add('schedule', this.schedule[day]).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.getHours()
          this.getSchedule()
        },
        err => {
          console.error(err)
        },
      )
    },

    async deleteSchedule(uuid) {
      if (uuid === '') {
        this.showSnackbar({
          text: 'Tidak bisa menghapus karena tidak ada Mata Pelajaran',
          color: 'error',
        })
      } else {
        await this.$services.ApiServices.destroy('schedule', uuid).then(({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.getHours()
          this.getSchedule()
        })
      }
    },
  },
}
</script>

<style>
.search > .v-input__control > .v-text-field__details {
  display: none;
}
.search > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
</style>
