<template>
  <MainCard>
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="(tabItem,index) in tabs"
          :key="tabItem.title"
          @click="reRenderComponent(index)"
        >
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <lesson-schedule
            :key="componentKey"
          ></lesson-schedule>
        </v-tab-item>
        <v-tab-item>
          <lesson-subject :key="componentKey2"></lesson-subject>
        </v-tab-item>
        <v-tab-item>
          <lesson-hours :key="componentKey3"></lesson-hours>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import LessonHours from './LessonHours.vue'
import LessonSchedule from './LessonSchedule.vue'
import LessonSubject from './LessonSubject.vue'

export default {
  name: 'Lesson',
  components: {
    MainCard,
    LessonSubject,
    LessonSchedule,
    LessonHours,
  },
  props: {},
  data() {
    return {
      componentKey: 0,
      componentKey2: 0,
      componentKey3: 0,
      tab: '',
      tabs: [{ title: 'Jadwal Pelajaran' }, { title: 'Mata Pelajaran' }, { title: 'Jam Pelajaran' }],
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey2 += 1
          break
        case 2:
          this.componentKey3 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
